// src/layouts/RootLayout.tsx
import { Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Navigation from '../components/shared/Navigation';
import { logger } from '../utils/logger';

const RootLayout = () => {
  const { isAuthenticated, user, isLoading } = useAuth();
  const location = useLocation();

  logger.debug('RootLayout rendering:', {
    isAuthenticated,
    isLoading,
    path: location.pathname,
    user: user?.email
  });

  return (
    <div className="min-h-screen bg-background">
      <Navigation 
        isAuthenticated={isAuthenticated}
        userEmail={user?.email}
      />
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default RootLayout;
