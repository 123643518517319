import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';

const LandingPage = () => {
  const { t } = useTranslation();
  useDocumentTitle('Smart Assistant Generation - Pyunto Intelligence');

  return (
    <div className="container mx-auto px-4 py-16">
      {/* Hero Section */}
      <section className="text-center mb-16">
        <h1 className="text-4xl md:text-6xl font-bold mb-6">
          {t('landing.hero.title')}
        </h1>
        <p className="text-xl mb-8 text-muted-foreground">
          {t('landing.hero.subtitle')}
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <a
            href="https://i.pyunto.com"
            className="inline-block bg-primary text-white px-8 py-4 rounded-md text-lg hover:bg-primary/90"
          >
            {t('landing.hero.cta')}
          </a>
          <Link
            to="/demo"
            className="inline-block bg-secondary text-white px-8 py-4 rounded-md text-lg hover:bg-secondary/90"
          >
            {t('landing.hero.tryDemo')}
          </Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="grid md:grid-cols-3 gap-8 mb-16">
        <div className="p-6 border rounded-lg">
          <h3 className="text-xl font-semibold mb-4">
            {t('landing.features.imageRecognition.title')}
          </h3>
          <p className="text-muted-foreground">
            {t('landing.features.imageRecognition.description')}
          </p>
        </div>
        <div className="p-6 border rounded-lg">
          <h3 className="text-xl font-semibold mb-4">
            {t('landing.features.textAnalysis.title')}
          </h3>
          <p className="text-muted-foreground">
            {t('landing.features.textAnalysis.description')}
          </p>
        </div>
        <div className="p-6 border rounded-lg">
          <h3 className="text-xl font-semibold mb-4">
            {t('landing.features.audioProcessing.title')}
          </h3>
          <p className="text-muted-foreground">
            {t('landing.features.audioProcessing.description')}
          </p>
        </div>
      </section>

      {/* API Integration Section */}
      <section className="mb-16">
        <h2 className="text-3xl font-bold mb-8 text-center">
          {t('landing.api.title')}
        </h2>
        <div className="bg-muted p-6 rounded-lg">
          <pre className="overflow-x-auto">
            <code className="text-sm">
              {`curl -X POST https://g.pyunto.com/api/i/v1 \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: application/json" \\
  -d '{"type": "image", "data": "base64_encoded_data"}'`}
            </code>
          </pre>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
