import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import SetPasswordForm from '../../components/auth/SetPasswordForm';
import { Alert, AlertDescription } from '../../components/ui/alert';
import { Button } from '../../components/ui/button';

interface DecodedParams {
  token: string | null;
  email: string | null;
  agency?: string | null;
}

const SetPasswordPage: React.FC = () => {
  console.log('SetPasswordPage rendering'); // Basic render log

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [decodedParams, setDecodedParams] = useState<DecodedParams>({
    token: null,
    email: null,
    agency: null
  });
  const [isProcessing, setIsProcessing] = useState(true);

  // Mount logging
  useEffect(() => {
    console.log('SetPasswordPage mounted');
    console.debug('Current location:', {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash
    });
    console.debug('Query parameters:', Array.from(searchParams.entries()));
  }, [location, searchParams]);

  useEffect(() => {
    const decodeParams = () => {
      try {
        console.debug('Starting parameter decoding process');
        
        // Get raw params from URL
        const rawToken = searchParams.get('token');
        const rawEmail = searchParams.get('email');
        const rawAgency = searchParams.get('agency');

        // Log raw parameters
        console.debug('Raw URL parameters:', {
          token: rawToken ? `${rawToken.substring(0, 10)}...` : 'null',
          email: rawEmail || 'null',
          agency: rawAgency || 'null',
          fullUrl: window.location.href
        });

        // Check for required parameters
        if (!rawToken || !rawEmail) {
          const missingParams = [];
          if (!rawToken) missingParams.push('token');
          if (!rawEmail) missingParams.push('email');
          
          console.debug('Missing required parameters:', missingParams);
          throw new Error(`Missing required parameters: ${missingParams.join(', ')}`);
        }

        // Log URL decoding process
        console.debug('URL decoding steps:', {
          originalToken: rawToken.substring(0, 10) + '...',
          originalEmail: rawEmail,
          decodedToken: decodeURIComponent(rawToken).substring(0, 10) + '...',
          decodedEmail: decodeURIComponent(rawEmail),
          containsPlus: rawEmail.includes('+'),
          containsEncodedPlus: rawEmail.includes('%2B')
        });

        // Decode email address
        let decodedEmail = decodeURIComponent(rawEmail);
        decodedEmail = decodedEmail.replace(/%2B/g, '+');

        // Decode token
        const decodedToken = decodeURIComponent(rawToken);

        // Decode agency code if present
        const decodedAgency = rawAgency ? decodeURIComponent(rawAgency) : null;

        console.debug('Final decoded values:', {
          email: decodedEmail,
          tokenLength: decodedToken.length,
          hasAgency: !!decodedAgency
        });

        // Email validation pattern
        const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const isValidEmail = emailPattern.test(decodedEmail);

        console.debug('Email validation:', {
          email: decodedEmail,
          isValid: isValidEmail
        });

        if (!isValidEmail) {
          throw new Error('Invalid email format');
        }

        setDecodedParams({
          token: decodedToken,
          email: decodedEmail,
          agency: decodedAgency
        });
        setError(null);

        console.debug('Parameters successfully decoded and stored');

      } catch (err) {
        console.error('Parameter processing error:', {
          error: err,
          message: err instanceof Error ? err.message : 'Unknown error',
          stack: err instanceof Error ? err.stack : undefined
        });
        setError(err instanceof Error ? err.message : 'Invalid setup link parameters');
        setDecodedParams({ token: null, email: null, agency: null });
      } finally {
        setIsProcessing(false);
      }
    };

    decodeParams();
  }, [searchParams]);

  // Debug render state
  console.debug('Current render state:', {
    isProcessing,
    hasError: !!error,
    hasDecodedParams: !!(decodedParams.email && decodedParams.token),
    error
  });

  if (isProcessing) {
    return (
      <div className="min-h-screen flex items-center justify-center px-4">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto mb-4" />
          <p className="text-gray-600">Processing setup link...</p>
        </div>
      </div>
    );
  }

  if (error || !decodedParams.email || !decodedParams.token) {
    return (
      <div className="min-h-screen flex items-center justify-center px-4">
        <div className="max-w-md w-full">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md text-center">
            <h2 className="text-2xl font-bold mb-4">Setup Link Error</h2>
            <Alert variant="destructive">
              <AlertDescription>
                {error || 'Invalid setup link parameters'}
              </AlertDescription>
            </Alert>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-4 mb-6">
              Please check your email for a valid setup link or contact support if the problem persists.
            </p>
            <div className="space-y-2">
              <Button
                onClick={() => window.location.reload()}
                variant="outline"
                className="w-full"
              >
                Try Again
              </Button>
              <Button
                onClick={() => window.location.href = 'mailto:pyunto@utagoe.com'}
                variant="link"
                className="w-full"
              >
                Contact Support
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="w-full max-w-md">
        <SetPasswordForm 
          email={decodedParams.email}
          token={decodedParams.token}
          agency={decodedParams.agency}
        />
        {import.meta.env.DEV && (
          <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-700 rounded-lg text-xs">
            <h3 className="font-bold mb-2">Debug Information:</h3>
            <pre className="whitespace-pre-wrap">
              {JSON.stringify({
                email: decodedParams.email,
                tokenLength: decodedParams.token?.length,
                hasAgency: !!decodedParams.agency,
                currentUrl: window.location.href
              }, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default SetPasswordPage;
