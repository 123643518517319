// src/components/auth/SetPasswordForm.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Alert, AlertDescription } from '../ui/alert';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';
import { useAuth } from '../../hooks/useAuth';
import { logger } from '../../utils/logger';
import axios from 'axios';

interface SetPasswordFormProps {
  email: string;
  token: string;
  agency?: string;
}

interface TokenValidationResponse {
  success: boolean;
  message?: string;
  errors?: Record<string, string>;
  data?: {
    isExpired: boolean;
    expiresAt: string;
  };
}

interface DebugInfo {
  originalEmail: string;
  decodedEmail: string;
  isValidFormat: boolean;
  validationErrors: string[];
  tokenStatus?: {
    isExpired: boolean;
    expiresAt: string;
  };
}

const SetPasswordForm: React.FC<SetPasswordFormProps> = ({ email, token, agency }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setInitialPassword } = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewTokenForm, setShowNewTokenForm] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenValidationAttempted, setTokenValidationAttempted] = useState(false);
  const [debugInfo, setDebugInfo] = useState<DebugInfo>({
    originalEmail: '',
    decodedEmail: '',
    isValidFormat: false,
    validationErrors: []
  });

  const apiUrl = import.meta.env.VITE_API_URL || '';
  const EMAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  useEffect(() => {
    const processEmail = () => {
      try {
        logger.debug('Initial email processing:', {
          email,
          token: token ? '(present)' : '(missing)'
        });

        if (!email) {
          logger.debug('Email is missing');
          setDebugInfo(prev => ({
            ...prev,
            validationErrors: [t('auth.setupPassword.errors.emailMissing')]
          }));
          return;
        }

        const decodedEmail = email.includes('%2B') ?
          decodeURIComponent(email) :
          email.replace(/\+/g, '+');

        logger.debug('Email decoding process:', {
          originalEmail: email,
          decodedEmail,
          containsEncodedPlus: email.includes('%2B'),
          containsPlus: email.includes('+')
        });

        const isValidFormat = EMAIL_PATTERN.test(decodedEmail);
        const validationErrors: string[] = [];

        if (!decodedEmail) {
          validationErrors.push(t('auth.setupPassword.errors.emptyEmail'));
        }
        if (!isValidFormat) {
          validationErrors.push(t('auth.setupPassword.errors.invalidFormat'));
        }
        if (decodedEmail === 'undefined') {
          validationErrors.push(t('auth.setupPassword.errors.undefinedEmail'));
        }

        setDebugInfo({
          originalEmail: email,
          decodedEmail,
          isValidFormat,
          validationErrors
        });

        logger.debug('Email validation results:', {
          isValid: isValidFormat,
          errors: validationErrors
        });
      } catch (error) {
        logger.error('Email processing error:', error);
        setDebugInfo(prev => ({
          ...prev,
          validationErrors: [...prev.validationErrors, t('auth.setupPassword.errors.processingError')]
        }));
      }
    };

    processEmail();
  }, [email, t]);

  useEffect(() => {
    const validateTokenAndParams = async () => {
      if (!email || !token || email === "undefined" || token === "undefined") {
        logger.debug('Invalid initial parameters:', { email, token });
        setError(t('auth.setupPassword.errors.invalid'));
        setShowNewTokenForm(true);
        return;
      }

      setIsLoading(true);

      try {
        const decodedEmail = email.includes('%2B') ?
          decodeURIComponent(email) :
          email.replace(/\+/g, '+');

        logger.debug('Token validation attempt:', {
          email: decodedEmail,
          tokenLength: token.length,
          debugInfo,
          emailContainsPlus: decodedEmail.includes('+')
        });

        if (!decodedEmail || decodedEmail === "undefined") {
          throw new Error(t('auth.setupPassword.errors.invalidEmail'));
        }

        const response = await axios.get<TokenValidationResponse>(
          `${apiUrl}/api/auth/verify-setup-token`,
          {
            params: {
              email: decodedEmail,
              token: decodeURIComponent(token)
            },
            headers: {
              'Content-Type': 'application/json'
            },
            timeout: 15000
          }
        );

        logger.debug('Token validation response:', response.data);

        if (response.data.data?.isExpired) {
          logger.debug('Token is expired:', response.data.data.expiresAt);
          setDebugInfo(prev => ({
            ...prev,
            tokenStatus: response.data.data
          }));
          throw new Error(t('auth.setupPassword.errors.expired'));
        }

        if (response.data.success) {
          setIsTokenValid(true);
          setError(null);
          logger.debug('Token validation successful');
          if (response.data.data) {
            setDebugInfo(prev => ({
              ...prev,
              tokenStatus: response.data.data
            }));
          }
        } else {
          logger.debug('Token validation failed:', response.data);
          throw new Error(t('auth.setupPassword.errors.invalid'));
        }
      } catch (err) {
        handleTokenValidationError(err);
      } finally {
        setIsLoading(false);
        setTokenValidationAttempted(true);
      }
    };

    validateTokenAndParams();
  }, [email, token, apiUrl, t]);

  const handleTokenValidationError = (err: any) => {
    logger.error('Token validation error:', {
      error: err,
      debugInfo
    });

    let errorMessage = t('auth.setupPassword.errors.invalid');
    
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        errorMessage = t('auth.setupPassword.errors.authFailed');
      } else if (err.response?.status === 400) {
        errorMessage = err.response.data.message || t('auth.setupPassword.errors.invalid');
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      }
    } else if (err instanceof Error) {
      errorMessage = err.message;
    }

    setError(errorMessage);
    setIsTokenValid(false);
    setShowNewTokenForm(true);
  };

  const validatePassword = (password: string): string[] => {
    const requirements = [
      {
        test: (p: string) => p.length >= 8,
        message: t('auth.setupPassword.passwordRequirements.length')
      },
      {
        test: (p: string) => /[A-Z]/.test(p),
        message: t('auth.setupPassword.passwordRequirements.uppercase')
      },
      {
        test: (p: string) => /[a-z]/.test(p),
        message: t('auth.setupPassword.passwordRequirements.lowercase')
      },
      {
        test: (p: string) => /[0-9]/.test(p),
        message: t('auth.setupPassword.passwordRequirements.number')
      },
      {
        test: (p: string) => /[^A-Za-z0-9]/.test(p),
        message: t('auth.setupPassword.passwordRequirements.special')
      }
    ];

    return requirements
      .filter(req => !req.test(password))
      .map(req => req.message);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isTokenValid) return;

    setError(null);
    setIsLoading(true);

    try {
      if (password !== confirmPassword) {
        throw new Error(t('auth.setupPassword.errors.passwordMismatch'));
      }

      const validationErrors = validatePassword(password);
      if (validationErrors.length > 0) {
        throw new Error(validationErrors[0]);
      }

      if (!debugInfo.decodedEmail || debugInfo.validationErrors.length > 0) {
        throw new Error(t('auth.setupPassword.errors.invalidEmail'));
      }

      const response = await axios.post(
        `${apiUrl}/api/auth/setup`,
        {
          email: debugInfo.decodedEmail,
          token: decodeURIComponent(token),
          password,
          agency
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 15000
        }
      );

      if (response.data.success) {
        if (response.data.data?.token) {
          localStorage.setItem('authToken', response.data.data.token);
          if (response.data.data.refreshToken) {
            localStorage.setItem('refreshToken', response.data.data.refreshToken);
          }
        }
        navigate('/dashboard');
      } else {
        throw new Error(response.data.message || t('auth.setupPassword.errors.generalError'));
      }
    } catch (err) {
      handleSetPasswordError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const requestNewToken = async () => {
    setError(null);
    setIsLoading(true);

    try {
      logger.debug('Starting new token request:', {
        currentDebugInfo: debugInfo
      });

      if (!debugInfo.decodedEmail || debugInfo.validationErrors.length > 0) {
        throw new Error(t('auth.setupPassword.errors.invalidEmail'));
      }

      const response = await axios.post(
        `${apiUrl}/api/auth/request-setup-token`,
        {
          email: debugInfo.decodedEmail,
          agency
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          timeout: 15000
        }
      );

      if (response.data.success) {
        setShowNewTokenForm(false);
        setError(t('auth.emailSent.description'));
      } else {
        throw new Error(response.data.message || t('auth.setupPassword.errors.generalError'));
      }
    } catch (err) {
      handleNewTokenError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // src/components/auth/SetPasswordForm.tsx (続き)

  const handleNewTokenError = (err: any) => {
    logger.error('New token request error:', {
      error: err,
      debugInfo
    });

    let errorMessage = t('auth.setupPassword.errors.generalError');

    if (axios.isAxiosError(err)) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        errorMessage = t('auth.setupPassword.errors.authFailed');
      } else if (err.response?.status === 400) {
        errorMessage = err.response.data.message || t('auth.setupPassword.errors.invalid');
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      }
    } else if (err instanceof Error) {
      errorMessage = err.message;
    }

    setError(errorMessage);
    setShowNewTokenForm(true);
  };

  if (!tokenValidationAttempted && isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto mb-4" />
          <p className="text-gray-600">{t('auth.setupPassword.validatingMessage')}</p>
        </div>
      </div>
    );
  }

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center">
          {t('auth.setupPassword.title')}
        </CardTitle>
      </CardHeader>

      <CardContent>
        {debugInfo.decodedEmail && debugInfo.isValidFormat && (
          <p className="text-gray-600 dark:text-gray-300 mb-6 text-center">
            {t('auth.setupPassword.settingUpFor')} {debugInfo.decodedEmail}
          </p>
        )}

        {error && (
          <Alert variant="destructive" className="mb-6">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {import.meta.env.DEV && (
          <div className="mb-4 p-2 bg-gray-100 dark:bg-gray-700 rounded text-xs">
            <h3 className="font-bold mb-1">Debug Information:</h3>
            <p>Original Email: {debugInfo.originalEmail}</p>
            <p>Decoded Email: {debugInfo.decodedEmail}</p>
            <p>Valid Format: {debugInfo.isValidFormat.toString()}</p>
            <p>Token Valid: {isTokenValid.toString()}</p>
            {debugInfo.tokenStatus && (
              <>
                <p>Token Expired: {debugInfo.tokenStatus.isExpired.toString()}</p>
                <p>Expires At: {debugInfo.tokenStatus.expiresAt}</p>
              </>
            )}
            {debugInfo.validationErrors.length > 0 && (
              <div>
                <p>Validation Errors:</p>
                <ul>
                  {debugInfo.validationErrors.map((error, index) => (
                    <li key={index}>- {error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {showNewTokenForm ? (
          <div className="text-center space-y-4">
            <p className="text-gray-600 dark:text-gray-300">
              {t('auth.setupPassword.requestNewLink.title')}
            </p>
            <Button
              onClick={requestNewToken}
              disabled={isLoading || !debugInfo.isValidFormat}
              className="w-full"
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  {t('auth.setupPassword.requestNewLink.sendingButton')}
                </div>
              ) : (
                t('auth.setupPassword.requestNewLink.button')
              )}
            </Button>
            {!debugInfo.isValidFormat && (
              <p className="mt-2 text-sm text-red-500">
                {t('auth.setupPassword.requestNewLink.invalidEmail')}
              </p>
            )}
            <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('auth.common.support.needHelp')}{' '}
                <a
                  href="mailto:pyunto@utagoe.com"
                  className="text-blue-500 hover:text-blue-600"
                >
                  pyunto@utagoe.com
                </a>
              </p>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="password" className="block text-sm font-medium mb-1">
                {t('auth.setupPassword.newPasswordLabel')}
              </label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength={8}
                className="w-full"
                placeholder={t('auth.setupPassword.newPasswordPlaceholder')}
                autoComplete="new-password"
                disabled={!isTokenValid || isLoading}
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium mb-1">
                {t('auth.setupPassword.confirmPasswordLabel')}
              </label>
              <Input
                id="confirmPassword"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="w-full"
                placeholder={t('auth.setupPassword.confirmPasswordPlaceholder')}
                autoComplete="new-password"
                disabled={!isTokenValid || isLoading}
              />
            </div>

            <div className="text-sm text-gray-600 dark:text-gray-400 space-y-1">
              <p>{t('auth.setupPassword.passwordRequirements.title')}</p>
              <ul className="list-disc pl-5 space-y-1">
                <li className={password.length >= 8 ? 'text-green-500 dark:text-green-400' : undefined}>
                  {t('auth.setupPassword.passwordRequirements.length')}
                </li>
                <li className={/[A-Z]/.test(password) ? 'text-green-500 dark:text-green-400' : undefined}>
                  {t('auth.setupPassword.passwordRequirements.uppercase')}
                </li>
                <li className={/[a-z]/.test(password) ? 'text-green-500 dark:text-green-400' : undefined}>
                  {t('auth.setupPassword.passwordRequirements.lowercase')}
                </li>
                <li className={/[0-9]/.test(password) ? 'text-green-500 dark:text-green-400' : undefined}>
                  {t('auth.setupPassword.passwordRequirements.number')}
                </li>
                <li className={/[^A-Za-z0-9]/.test(password) ? 'text-green-500 dark:text-green-400' : undefined}>
                  {t('auth.setupPassword.passwordRequirements.special')}
                </li>
              </ul>
            </div>

            <Button
              type="submit"
              disabled={!isTokenValid || isLoading}
              className="w-full"
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  {t('auth.setupPassword.processingButton')}
                </div>
              ) : (
                t('auth.setupPassword.submitButton')
              )}
            </Button>

            <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700 text-center">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {t('auth.common.support.havingTrouble')}{' '}
                <a
                  href="mailto:pyunto@utagoe.com"
                  className="text-blue-500 hover:text-blue-600"
                >
                  pyunto@utagoe.com
                </a>
              </p>
            </div>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default SetPasswordForm;
