// src/utils/logger.ts
type LogLevel = 'debug' | 'info' | 'warn' | 'error';

class Logger {
  private isDevelopment = import.meta.env.DEV;

  private log(level: LogLevel, message: string, ...args: any[]) {
    if (this.isDevelopment || level === 'error') {
      const timestamp = new Date().toISOString();
      const prefix = `[${timestamp}] ${level.toUpperCase()}:`;
      
      if (args.length > 0) {
        console[level](prefix, message, ...args);
      } else {
        console[level](prefix, message);
      }
    }
  }

  debug(message: string, ...args: any[]) {
    this.log('debug', message, ...args);
  }

  info(message: string, ...args: any[]) {
    this.log('info', message, ...args);
  }

  warn(message: string, ...args: any[]) {
    this.log('warn', message, ...args);
  }

  error(message: string, ...args: any[]) {
    this.log('error', message, ...args);
  }
}

export const logger = new Logger();
