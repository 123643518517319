import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import { Button } from '../ui/button';
import { Menu, X, LogOut } from 'lucide-react';
import ThemeToggle from './ThemeToggle';
import LanguageSwitch from './LanguageSwitch';

const Navigation = () => {
  const { isAuthenticated, logout, user } = useAuth();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      setIsOpen(false);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const NavLinks = () => (
    <>
      <a 
        href="https://i.pyunto.com"
        className="text-muted-foreground hover:text-foreground transition-colors"
        onClick={() => setIsOpen(false)}
      >
        {t('navigation.links.plansAndPricing')}
      </a>
      
      <Link 
        to="/demo"
        className="text-muted-foreground hover:text-foreground transition-colors"
        onClick={() => setIsOpen(false)}
      >
        {t('navigation.links.demo')}
      </Link>

      {isAuthenticated && (
        <Link 
          to="/dashboard"
          className="text-muted-foreground hover:text-foreground transition-colors"
          onClick={() => setIsOpen(false)}
        >
          {t('navigation.links.dashboard')}
        </Link>
      )}
    </>
  );

  return (
    <nav className="border-b">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold">
            Pyunto Intelligence
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-6">
            <NavLinks />
            
            {/* Auth buttons or user info */}
            {isAuthenticated ? (
              <div className="flex items-center space-x-4">
                <span className="text-sm text-muted-foreground">
                  {user?.email}
                </span>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleLogout}
                  className="flex items-center gap-2"
                >
                  <LogOut size={16} />
                  {t('navigation.buttons.logout')}
                </Button>
              </div>
            ) : (
              <Link to="/auth/login">
                <Button variant="ghost" size="sm">
                  {t('navigation.buttons.login')}
                </Button>
              </Link>
            )}

            {/* Language switch and theme toggle */}
            <div className="flex items-center space-x-4">
              <LanguageSwitch showFlag={true} />
              <div className="border-l border-border pl-4">
                <ThemeToggle />
              </div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </Button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <div className="md:hidden border-t py-4 space-y-4">
            <div className="flex flex-col space-y-4">
              <NavLinks />
            </div>
            
            <div className="flex flex-col space-y-4 pt-4 border-t">
              {isAuthenticated ? (
                <>
                  <span className="text-sm text-muted-foreground">
                    {user?.email}
                  </span>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={handleLogout}
                    className="flex items-center gap-2"
                  >
                    <LogOut size={16} />
                    {t('navigation.buttons.logout')}
                  </Button>
                </>
              ) : (
                <Link to="/auth/login">
                  <Button variant="ghost" size="sm" className="w-full">
                    {t('navigation.buttons.login')}
                  </Button>
                </Link>
              )}
            </div>

            <div className="flex items-center justify-between pt-4 border-t">
              <LanguageSwitch showFlag={true} />
              <ThemeToggle />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
