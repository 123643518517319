import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import './i18n/config';
import './styles/globals.css';

// Enable all console output in development
if (import.meta.env.DEV) {
  console.log('Initializing application in development mode');
  
  // Enable all console levels
  const originalConsole = { ...console };
  Object.keys(originalConsole).forEach(key => {
    if (typeof originalConsole[key] === 'function') {
      console[key] = (...args: any[]) => {
        originalConsole[key](...args);
      };
    }
  });

  // Add unhandled error logging
  window.addEventListener('error', (event) => {
    console.error('Unhandled error:', event.error);
  });

  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled promise rejection:', event.reason);
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);
