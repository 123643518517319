import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar, Clock, Activity, Filter } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend
} from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// Types for the usage data
interface ApiUsage {
  timestamp: string;
  count: number;
  keyId?: string;
  keyName?: string;
}

interface UsageMetricsProps {
  monthlyLimit: number;
  currentUsage: number;
}

const UsageMetrics = ({ monthlyLimit, currentUsage }: UsageMetricsProps) => {
  const { t } = useTranslation();
  const [timeframe, setTimeframe] = useState<'day' | 'week' | 'month'>('day');
  const [selectedApiKey, setSelectedApiKey] = useState<string>('all');

  // Sample data - replace with actual API data
  const sampleData: ApiUsage[] = [
    { timestamp: '00:00', count: 120 },
    { timestamp: '04:00', count: 180 },
    { timestamp: '08:00', count: 240 },
    { timestamp: '12:00', count: 300 },
    { timestamp: '16:00', count: 280 },
    { timestamp: '20:00', count: 220 },
  ];

  // Sample API keys - replace with actual data
  const apiKeys = [
    { id: 'all', name: 'All Keys' },
    { id: 'key1', name: 'Production API Key' },
    { id: 'key2', name: 'Development API Key' },
  ];

  const usagePercentage = (currentUsage / monthlyLimit) * 100;
  const isNearLimit = usagePercentage >= 80;
  const isOverLimit = usagePercentage >= 100;

  return (
    <div className="space-y-6">
      {/* Usage Summary Card */}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div>
              <CardTitle>{t('dashboard.usage.title')}</CardTitle>
              <CardDescription>{t('dashboard.usage.description')}</CardDescription>
            </div>
            <div className="flex items-center gap-4">
              <Select 
                value={timeframe} 
                onValueChange={(value: 'day' | 'week' | 'month') => setTimeframe(value)}
              >
                <SelectTrigger className="w-[120px]">
                  <Clock className="w-4 h-4 mr-2" />
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="day">{t('dashboard.usage.timeframe.day')}</SelectItem>
                  <SelectItem value="week">{t('dashboard.usage.timeframe.week')}</SelectItem>
                  <SelectItem value="month">{t('dashboard.usage.timeframe.month')}</SelectItem>
                </SelectContent>
              </Select>
              
              <Select 
                value={selectedApiKey}
                onValueChange={setSelectedApiKey}
              >
                <SelectTrigger className="w-[180px]">
                  <Filter className="w-4 h-4 mr-2" />
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {apiKeys.map(key => (
                    <SelectItem key={key.id} value={key.id}>
                      {key.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {/* Usage Alerts */}
          {isOverLimit && (
            <Alert variant="destructive" className="mb-4">
              <AlertDescription>
                {t('dashboard.usage.alerts.overLimit')}
              </AlertDescription>
            </Alert>
          )}
          {isNearLimit && !isOverLimit && (
            <Alert className="mb-4">
              <AlertDescription>
                {t('dashboard.usage.alerts.nearLimit')}
              </AlertDescription>
            </Alert>
          )}

          {/* Usage Progress */}
          <div className="mb-6">
            <div className="flex justify-between mb-2">
              <span className="text-sm font-medium">
                {t('dashboard.usage.progress.current')}: {currentUsage.toLocaleString()}
              </span>
              <span className="text-sm text-muted-foreground">
                {t('dashboard.usage.progress.limit')}: {monthlyLimit.toLocaleString()}
              </span>
            </div>
            <div className="h-2 bg-muted rounded-full">
              <div
                className={`h-2 rounded-full ${
                  isOverLimit
                    ? 'bg-destructive'
                    : isNearLimit
                    ? 'bg-warning'
                    : 'bg-primary'
                }`}
                style={{ width: `${Math.min(usagePercentage, 100)}%` }}
              />
            </div>
          </div>

          {/* Usage Chart */}
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={sampleData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  tick={{ fontSize: 12 }}
                  tickFormatter={(value) => value}
                />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip
                  contentStyle={{ backgroundColor: '#1f2937', border: 'none' }}
                  itemStyle={{ color: '#ffffff' }}
                  labelStyle={{ color: '#ffffff' }}
                />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#3b82f6"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Usage by API Key */}
          {selectedApiKey === 'all' && (
            <div className="mt-6 h-[300px]">
              <h3 className="text-lg font-semibold mb-4">
                {t('dashboard.usage.byKey.title')}
              </h3>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={[
                  { name: 'Production API Key', value: 2500 },
                  { name: 'Development API Key', value: 1500 },
                ]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip
                    contentStyle={{ backgroundColor: '#1f2937', border: 'none' }}
                    itemStyle={{ color: '#ffffff' }}
                    labelStyle={{ color: '#ffffff' }}
                  />
                  <Bar dataKey="value" fill="#3b82f6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default UsageMetrics;
