// src/i18n/config.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import all translation files
import enTranslations from './locales/en.json';
import jaTranslations from './locales/ja.json';
import zhCNTranslations from './locales/zh-CN.json';
import zhTWTranslations from './locales/zh-TW.json';

// Supported languages configuration
const SUPPORTED_LANGUAGES = ['en', 'ja', 'zh-CN', 'zh-TW'] as const;
type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];

// Function to detect browser language and map to supported language
const detectBrowserLanguage = (): SupportedLanguage => {
  const browserLang = navigator.language.toLowerCase();
  
  if (browserLang.startsWith('ja')) return 'ja';
  if (browserLang.startsWith('zh')) {
    // Handle traditional Chinese variants
    if (['zh-tw', 'zh-hk'].includes(browserLang)) return 'zh-TW';
    return 'zh-CN';
  }
  return 'en';
};

// Function to get initial language
const getInitialLanguage = (): SupportedLanguage => {
  const savedLang = localStorage.getItem('language') as SupportedLanguage;
  if (savedLang && SUPPORTED_LANGUAGES.includes(savedLang)) {
    return savedLang;
  }
  
  const browserLang = detectBrowserLanguage();
  localStorage.setItem('language', browserLang);
  return browserLang;
};

const resources = {
  en: {
    translation: enTranslations,
  },
  ja: {
    translation: jaTranslations,
  },
  'zh-CN': {
    translation: zhCNTranslations,
  },
  'zh-TW': {
    translation: zhTWTranslations,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getInitialLanguage(),
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },

    // Language detection options
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

// Add language change listener to update localStorage
i18n.on('languageChanged', (lng: string) => {
  if (SUPPORTED_LANGUAGES.includes(lng as SupportedLanguage)) {
    localStorage.setItem('language', lng);
  }
});

export default i18n;
