// src/components/dashboard/AssistantConfiguration.tsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Plus, Save, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { useAuth } from '../../hooks/useAuth';
import { logger } from '../../utils/logger';
import { FeatureType, PlanFeatures, Feature, Assistant } from '../../types/dashboard';

interface NewAssistant {
  id: string;
  name: string;
  featureType: FeatureType;
  features: Feature[];
}

const AssistantConfiguration = () => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [planFeatures, setPlanFeatures] = useState<PlanFeatures | null>(null);
  const [newAssistant, setNewAssistant] = useState<NewAssistant>({
    id: '',
    name: '',
    featureType: 'IMAGE',
    features: []
  });
  const [newFeature, setNewFeature] = useState('');
  const [saveStatus, setSaveStatus] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPlanFeatures = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const response = await fetch('/api/dashboard/plan-features', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(t('dashboard.assistant.errors.planFeaturesFetch'));
        }

        const data = await response.json();
        if (!data.success || !data.data) {
          throw new Error(t('dashboard.assistant.errors.invalidData'));
        }

        // Ensure supportedTypes is always an array
        const features = {
          ...data.data,
          supportedTypes: Array.isArray(data.data.supportedTypes) 
            ? data.data.supportedTypes 
            : []
        };

        setPlanFeatures(features);
        
        if (features.supportedTypes.length > 0) {
          setNewAssistant(prev => ({
            ...prev,
            featureType: features.supportedTypes[0]
          }));
        }
      } catch (error) {
        logger.error('Error fetching plan features:', error);
        setError(error instanceof Error ? error.message : t('dashboard.assistant.errors.unknown'));
      } finally {
        setIsLoading(false);
      }
    };

    const fetchAssistants = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/dashboard/assistants', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch assistants');
        }

        const data = await response.json();
        if (!data.success || !Array.isArray(data.data)) {
          throw new Error(t('dashboard.assistant.errors.invalidData'));
        }

        setAssistants(data.data);
      } catch (error) {
        logger.error('Error fetching assistants:', error);
        setError(error instanceof Error ? error.message : t('dashboard.assistant.errors.unknown'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlanFeatures();
    fetchAssistants();
  }, [token, t]);

  const handleAddFeature = () => {
    if (newFeature.trim()) {
      setNewAssistant(prev => ({
        ...prev,
        features: [...prev.features, { id: crypto.randomUUID(), description: newFeature.trim() }]
      }));
      setNewFeature('');
    }
  };

  const handleRemoveFeature = (featureId: string) => {
    setNewAssistant(prev => ({
      ...prev,
      features: prev.features.filter(f => f.id !== featureId)
    }));
  };

  const handleSaveAssistant = async () => {
    if (!newAssistant.name.trim() || newAssistant.features.length === 0) {
      setSaveStatus(t('dashboard.assistant.validation.required'));
      return;
    }

    try {
      setIsLoading(true);
      setSaveStatus('');
      
      const response = await fetch('/api/dashboard/assistants', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: newAssistant.name,
          featureType: newAssistant.featureType,
          features: newAssistant.features
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save assistant');
      }

      const data = await response.json();
      if (!data.success || !data.data) {
        throw new Error(t('dashboard.assistant.errors.invalidData'));
      }

      setAssistants(prev => [...prev, data.data]);
      setNewAssistant({
        id: '',
        name: '',
        featureType: planFeatures?.supportedTypes[0] || 'IMAGE',
        features: []
      });
      setSaveStatus(t('dashboard.assistant.saveSuccess'));
    } catch (error) {
      logger.error('Error saving assistant:', error);
      setSaveStatus(t('dashboard.assistant.saveError'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAssistant = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(`/api/dashboard/assistants/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete assistant');
      }

      setAssistants(prev => prev.filter(assistant => assistant.id !== id));
      setSaveStatus(t('dashboard.assistant.deleteSuccess'));
    } catch (error) {
      logger.error('Error deleting assistant:', error);
      setSaveStatus(t('dashboard.assistant.deleteError'));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading && !planFeatures) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <div className="text-center">{t('common.loading')}</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-full">
        <CardContent className="p-6">
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>{t('dashboard.assistant.title')}</CardTitle>
        <CardDescription>
          {t('dashboard.assistant.description')}
          {planFeatures && (
            <div className="mt-2 text-sm">
              {t('dashboard.assistant.monthlyLimit', { limit: planFeatures.monthlyApiLimit.toLocaleString() })}
            </div>
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          {/* Assistant Configuration Form */}
          <div className="space-y-4">
            <div>
              <Label htmlFor="assistant-name">
                {t('dashboard.assistant.form.name')}
              </Label>
              <Input
                id="assistant-name"
                value={newAssistant.name}
                onChange={(e) => setNewAssistant(prev => ({ ...prev, name: e.target.value }))}
                placeholder={t('dashboard.assistant.form.namePlaceholder')}
                className="mt-1"
                disabled={isLoading}
              />
            </div>

            {planFeatures?.supportedTypes.length > 0 && (
              <div>
                <Label>{t('dashboard.assistant.form.type')}</Label>
                <RadioGroup
                  value={newAssistant.featureType}
                  onValueChange={(value: FeatureType) => 
                    setNewAssistant(prev => ({ ...prev, featureType: value }))}
                  className="flex space-x-4 mt-1"
                >
                  {planFeatures.supportedTypes.map((type) => (
                    <div key={type} className="flex items-center space-x-2">
                      <RadioGroupItem value={type} id={type.toLowerCase()} />
                      <Label htmlFor={type.toLowerCase()}>
                        {t(`dashboard.assistant.form.types.${type.toLowerCase()}`)}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
            )}

            {/* Features Input */}
            <div>
              <Label>{t('dashboard.assistant.form.features')}</Label>
              <div className="flex space-x-2 mt-1">
                <Input
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  placeholder={t('dashboard.assistant.form.featurePlaceholder')}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddFeature()}
                  disabled={isLoading}
                />
                <Button 
                  onClick={handleAddFeature} 
                  size="icon" 
                  disabled={!newFeature.trim() || isLoading}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>

              {/* Feature List */}
              <div className="mt-2 space-y-2">
                {newAssistant.features.map((feature) => (
                  <div key={feature.id} className="flex items-center justify-between p-2 border rounded">
                    <span>{feature.description}</span>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleRemoveFeature(feature.id)}
                      disabled={isLoading}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            {/* Save Button */}
            <Button
              onClick={handleSaveAssistant}
              className="w-full"
              disabled={isLoading || !newAssistant.name.trim() || newAssistant.features.length === 0}
            >
              <Save className="h-4 w-4 mr-2" />
              {isLoading ? t('common.saving') : t('dashboard.assistant.form.save')}
            </Button>
          </div>

          {/* Existing Assistants */}
          {assistants.length > 0 && (
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">
                {t('dashboard.assistant.savedAssistants')}
              </h3>
              <div className="space-y-4">
                {assistants.map((assistant) => (
                  <div key={assistant.id} className="p-4 border rounded">
                    <div className="flex justify-between items-start">
                      <div>
                        <h4 className="font-medium">{assistant.name}</h4>
                        <p className="text-sm text-muted-foreground">
                          {t('dashboard.assistant.type')}: {assistant.featureType}
                        </p>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleDeleteAssistant(assistant.id)}
                        disabled={isLoading}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm font-medium">
                        {t('dashboard.assistant.features')}:
                      </p>
                      <ul className="mt-1 space-y-1">
                        {assistant.features.map((feature) => (
                          <li key={feature.id} className="text-sm text-muted-foreground">
                            • {feature.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Status Messages */}
          {saveStatus && (
            <Alert variant={saveStatus.includes('error') ? 'destructive' : 'default'}>
              <AlertDescription>{saveStatus}</AlertDescription>
            </Alert>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AssistantConfiguration;
