// src/components/dashboard/AccountSettings.tsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Lock, Bell } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface NotificationSettings {
  apiUsage: boolean;
  billing: boolean;
  updates: boolean;
}

const AccountSettings = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [notifications, setNotifications] = useState<NotificationSettings>({
    apiUsage: true,
    billing: true,
    updates: false,
  });
  const [status, setStatus] = useState('');

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setStatus(t('dashboard.settings.password.errors.mismatch'));
      return;
    }
    try {
      // API call would go here
      setStatus(t('dashboard.settings.password.success'));
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setStatus(t('dashboard.settings.password.errors.updateFailed'));
      console.error('Error updating password:', error);
    }
  };

  const handleNotificationToggle = (key: keyof NotificationSettings) => {
    try {
      // API call would go here
      setNotifications(prev => ({
        ...prev,
        [key]: !prev[key]
      }));
    } catch (error) {
      console.error('Error updating notification settings:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Password Change Section */}
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <Lock className="h-5 w-5" />
            <CardTitle>{t('dashboard.settings.password.title')}</CardTitle>
          </div>
          <CardDescription>{t('dashboard.settings.password.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <Label htmlFor="current-password">
                {t('dashboard.settings.password.currentPassword')}
              </Label>
              <Input
                id="current-password"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <Label htmlFor="new-password">
                {t('dashboard.settings.password.newPassword')}
              </Label>
              <Input
                id="new-password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="mt-1"
              />
            </div>
            <div>
              <Label htmlFor="confirm-password">
                {t('dashboard.settings.password.confirmPassword')}
              </Label>
              <Input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="mt-1"
              />
            </div>
            <Button
              type="submit"
              disabled={!currentPassword || !newPassword || !confirmPassword}
              className="w-full"
            >
              {t('dashboard.settings.password.updateButton')}
            </Button>
          </form>
        </CardContent>
      </Card>

      {/* Email and Notification Settings */}
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <Bell className="h-5 w-5" />
            <CardTitle>{t('dashboard.settings.notifications.title')}</CardTitle>
          </div>
          <CardDescription>{t('dashboard.settings.notifications.description')}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="flex items-center justify-between py-2">
              <div>
                <h4 className="font-medium">{t('dashboard.settings.notifications.apiUsage.title')}</h4>
                <p className="text-sm text-muted-foreground">
                  {t('dashboard.settings.notifications.apiUsage.description')}
                </p>
              </div>
              <Button
                variant={notifications.apiUsage ? "default" : "outline"}
                onClick={() => handleNotificationToggle('apiUsage')}
              >
                {t(`dashboard.settings.notifications.${notifications.apiUsage ? 'enabled' : 'disabled'}`)}
              </Button>
            </div>
            <div className="flex items-center justify-between py-2">
              <div>
                <h4 className="font-medium">{t('dashboard.settings.notifications.billing.title')}</h4>
                <p className="text-sm text-muted-foreground">
                  {t('dashboard.settings.notifications.billing.description')}
                </p>
              </div>
              <Button
                variant={notifications.billing ? "default" : "outline"}
                onClick={() => handleNotificationToggle('billing')}
              >
                {t(`dashboard.settings.notifications.${notifications.billing ? 'enabled' : 'disabled'}`)}
              </Button>
            </div>
            <div className="flex items-center justify-between py-2">
              <div>
                <h4 className="font-medium">{t('dashboard.settings.notifications.updates.title')}</h4>
                <p className="text-sm text-muted-foreground">
                  {t('dashboard.settings.notifications.updates.description')}
                </p>
              </div>
              <Button
                variant={notifications.updates ? "default" : "outline"}
                onClick={() => handleNotificationToggle('updates')}
              >
                {t(`dashboard.settings.notifications.${notifications.updates ? 'enabled' : 'disabled'}`)}
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Status Messages */}
      {status && (
        <Alert>
          <AlertDescription>{status}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default AccountSettings;
