// src/components/shared/LanguageSwitch.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';

const languages = [
  { code: 'ja', name: '日本語', flag: '🇯🇵' },
  { code: 'en', name: 'English', flag: '🇺🇸' },
  { code: 'zh-CN', name: '简体中文', flag: '🇨🇳' },
  { code: 'zh-TW', name: '繁體中文', flag: '🇹🇼' }
] as const;

interface LanguageSwitchProps {
  showFlag?: boolean;
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = ({ showFlag = false }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (langCode: string) => {
    i18n.changeLanguage(langCode);
    // localStorage update is now handled by i18n.on('languageChanged') listener
  };

  // Find current language or fallback to first language
  const currentLang = languages.find(lang => lang.code === i18n.language) || languages[0];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center items-center px-3 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <span className="mr-2 text-base">{currentLang.flag}</span>
        <span>{currentLang.name}</span>
      </Menu.Button>

      <Menu.Items className="absolute right-0 mt-2 w-40 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
        <div className="px-1 py-1">
          {languages.map((lang) => (
            <Menu.Item key={lang.code}>
              {({ active }) => (
                <button
                  className={`${
                    active
                      ? 'bg-primary text-white'
                      : 'text-gray-900 dark:text-gray-200'
                  } ${
                    lang.code === i18n.language
                      ? 'bg-accent'
                      : ''
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => changeLanguage(lang.code)}
                >
                  <span className="mr-2 text-base">{lang.flag}</span>
                  {lang.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  );
};

export default LanguageSwitch;
