// src/components/dashboard/Dashboard.tsx

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow, format } from 'date-fns';
import { ja, enUS, zhCN, zhTW } from 'date-fns/locale';
import { Activity, Copy, Key, Plus, Settings, Sparkles, Trash2 } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { 
  Dialog,
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger,
  DialogFooter,
  DialogDescription 
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { useAuth } from '../../hooks/useAuth';
import AssistantConfiguration from './AssistantConfiguration';
import AccountSettings from './AccountSettings';
import UsageMetrics from './UsageMetrics';
import { logger } from '../../utils/logger';

interface ApiKey {
  id: string;
  name: string;
  created_at: string;
  last_used_at: string | null;
  status: string;
  key?: string;
}

type TabType = 'keys' | 'assistant' | 'usage' | 'settings';

interface DeleteDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  keyName: string;
  isLoading: boolean;
}

const DeleteDialog = ({ isOpen, onClose, onConfirm, keyName, isLoading }: DeleteDialogProps) => {
  const { t } = useTranslation();
  
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('dashboard.apiKeys.deleteDialog.title')}</DialogTitle>
          <DialogDescription>
            {t('dashboard.apiKeys.deleteDialog.description', { name: keyName })}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="gap-2 sm:gap-0">
          <Button
            variant="outline"
            onClick={onClose}
            disabled={isLoading}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="destructive"
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading ? t('common.deleting') : t('common.delete')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const API_BASE_URL = '/api';

const Dashboard = () => {
  const { isAuthenticated, token } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabType>('keys');
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedKey, setSelectedKey] = useState<ApiKey | null>(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tabs: { id: TabType; label: string; icon: React.ReactNode }[] = [
    { id: 'keys', label: 'dashboard.tabs.apiKeys', icon: <Key className="h-4 w-4" /> },
    { id: 'assistant', label: 'dashboard.tabs.assistant', icon: <Sparkles className="h-4 w-4" /> },
    { id: 'usage', label: 'dashboard.tabs.usage', icon: <Activity className="h-4 w-4" /> },
    { id: 'settings', label: 'dashboard.tabs.settings', icon: <Settings className="h-4 w-4" /> }
  ];

  const getLocale = () => {
    const lang = i18n.language;
    switch (lang) {
      case 'ja':
        return ja;
      case 'zh-CN':
        return zhCN;
      case 'zh-TW':
        return zhTW;
      default:
        return enUS;
    }
  };

  const formatDate = (date: string | Date | null) => {
    if (!date) return t('common.never');
    
    try {
      const dateObj = typeof date === 'string' ? new Date(date) : date;
      if (isNaN(dateObj.getTime())) {
        logger.warn('Invalid date value:', date);
        return t('common.never');
      }

      return format(dateObj, 'yyyy/MM/dd HH:mm', { 
        locale: getLocale(),
      });
    } catch (error) {
      logger.error('Error formatting date:', error);
      return t('common.never');
    }
  };

  const formatRelativeTime = (date: string | Date | null) => {
    if (!date) return t('common.never');
    
    try {
      const dateObj = typeof date === 'string' ? new Date(date) : date;
      if (isNaN(dateObj.getTime())) {
        logger.warn('Invalid date value:', date);
        return t('common.never');
      }

      return formatDistanceToNow(dateObj, {
        addSuffix: true,
        locale: getLocale(),
      });
    } catch (error) {
      logger.error('Error formatting relative time:', error);
      return t('common.never');
    }
  };

  const fetchApiKeys = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${API_BASE_URL}/keys`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('dashboard.apiKeys.errors.fetchFailed'));
      }
      
      const data = await response.json();
      setApiKeys(data);
    } catch (error) {
      setError(error instanceof Error ? error.message : t('dashboard.apiKeys.errors.unknown'));
      logger.error('Error fetching API keys:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateKey = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${API_BASE_URL}/keys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ name: newKeyName.trim() })
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 409) {
          throw new Error(t('dashboard.apiKeys.errors.duplicateName', { name: newKeyName }));
        }
        throw new Error(errorData.message || t('dashboard.apiKeys.errors.createFailed'));
      }
      
      const newKey = await response.json();
      setApiKeys(prevKeys => [...prevKeys, newKey]);
      setNewKeyName('');
      setShowCreateDialog(false);

      if (newKey.key) {
        await copyToClipboard(newKey.key);
        setCopySuccess(t('dashboard.apiKeys.newKeyCreated'));
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : t('dashboard.apiKeys.errors.unknown'));
      logger.error('Failed to create API key:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = (key: ApiKey) => {
    setSelectedKey(key);
    setShowDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedKey) return;

    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${API_BASE_URL}/keys/${selectedKey.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('dashboard.apiKeys.errors.deleteFailed'));
      }
      
      setApiKeys(prevKeys => prevKeys.filter(key => key.id !== selectedKey.id));
      setCopySuccess(t('dashboard.apiKeys.deleteSuccess', { name: selectedKey.name }));
    } catch (error) {
      setError(error instanceof Error ? error.message : t('dashboard.apiKeys.errors.unknown'));
      logger.error('Failed to delete API key:', error);
    } finally {
      setIsLoading(false);
      setShowDeleteDialog(false);
      setSelectedKey(null);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(t('common.copied'));
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      setError(t('common.copyFailed'));
      logger.error('Failed to copy text:', err);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    if (activeTab === 'keys') {
      fetchApiKeys();
    }
  }, [activeTab, isAuthenticated, navigate, t]);

  const renderApiKeysContent = () => (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle>{t('dashboard.apiKeys.title')}</CardTitle>
          <CardDescription>{t('dashboard.apiKeys.description')}</CardDescription>
        </div>
        <Dialog open={showCreateDialog} onOpenChange={setShowCreateDialog}>
          <DialogTrigger asChild>
            <Button className="flex items-center gap-2" disabled={isLoading}>
              <Plus size={16} />
              {t('dashboard.apiKeys.createButton')}
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{t('dashboard.apiKeys.createDialog.title')}</DialogTitle>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <Input
                placeholder={t('dashboard.apiKeys.createDialog.namePlaceholder')}
                value={newKeyName}
                onChange={(e) => setNewKeyName(e.target.value)}
                disabled={isLoading}
              />
              <Button
                onClick={handleCreateKey}
                disabled={!newKeyName.trim() || isLoading}
                className="w-full"
              >
                {isLoading ? t('common.loading') : t('dashboard.apiKeys.createDialog.createButton')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </CardHeader>
      <CardContent>
        {isLoading && apiKeys.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            {t('common.loading')}
          </div>
        ) : apiKeys.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            {t('dashboard.apiKeys.noKeys')}
          </div>
        ) : (
          <div className="space-y-4">
            {apiKeys.map((key) => (
              <div
                key={key.id}
                className="flex items-center justify-between p-4 border rounded-lg"
              >
                <div className="flex items-center gap-4">
                  <Key className="text-muted-foreground" size={20} />
                  <div>
                    <h3 className="font-medium">{key.name}</h3>
                    <p className="text-sm text-muted-foreground">
                      {t('dashboard.apiKeys.keyInfo.created')}: {formatDate(key.created_at)}
                      {key.last_used_at && (
                        <> • {t('dashboard.apiKeys.keyInfo.lastUsed')}: {formatRelativeTime(key.last_used_at)}</>
                      )}
                      {!key.last_used_at && <> • {t('dashboard.apiKeys.keyInfo.never')}</>}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  {key.key && (
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => copyToClipboard(key.key!)}
                      title={t('common.copy')}
                      disabled={isLoading}
                    >
                      <Copy size={16} />
                    </Button>
                  )}
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => handleDeleteClick(key)}
                    title={t('common.delete')}
                    disabled={isLoading}
                  >
                    <Trash2 size={16} />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {error && (
          <Alert variant="destructive" className="mt-4">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        
        {copySuccess && (
          <Alert className="mt-4">
            <AlertDescription>{copySuccess}</AlertDescription>
          </Alert>
        )}

        <DeleteDialog
          isOpen={showDeleteDialog}
          onClose={() => {
            setShowDeleteDialog(false);
            setSelectedKey(null);
          }}
          onConfirm={handleDeleteConfirm}
          keyName={selectedKey?.name || ''}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );

  const renderContent = () => {
    switch (activeTab) {
      case 'keys':
        return renderApiKeysContent();
      case 'assistant':
        return <AssistantConfiguration />;
      case 'usage':
        return <UsageMetrics monthlyLimit={40000} currentUsage={25000} />;
      case 'settings':
        return <AccountSettings />;
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <div className="flex space-x-1 border-b">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`flex items-center gap-2 px-4 py-2 transition-colors ${
                activeTab === tab.id
                  ? 'border-b-2 border-primary text-primary font-medium'
                  : 'text-muted-foreground hover:text-foreground'
              }`}
              onClick={() => setActiveTab(tab.id)}
	      disabled={isLoading}
            >
              {tab.icon}
              <span>{t(tab.label)}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="h-full">
        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
